import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useStores } from "../../store/store_context";

const HookahModal = ({ isOpen, onClose, hookahId, updateHookahs }) => {
  const [hookah, setHookah] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const { pageStore } = useStores();
  const toast = useToast();

  // Получение данных кальяна для редактирования
  useEffect(() => {
    const fetchHookah = async () => {
      try {
        const response = await fetch(
          `http://212.193.27.115:8888/hookahs/id/${hookahId}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${pageStore.token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setHookah(data);
        } else {
          toast({
            title: "Ошибка",
            description: "Ошибка загрузки данных кальяна",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Ошибка:", error);
        toast({
          title: "Ошибка",
          description: `Ошибка: ${error.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    if (hookahId) {
      fetchHookah();
    }
  }, [hookahId, pageStore.token]);

  // Функция для обновления кальяна
  const updateHookah = async () => {
    try {
      const response = await fetch(
        `http://212.193.27.115:8888/hookahs/update/${hookahId}`,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${pageStore.token}`,
          },
          body: JSON.stringify(hookah),
        }
      );

      if (response.ok) {
        toast({
          title: "Успех",
          description: "Кальян успешно обновлён",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        updateHookahs();

        onClose();
      } else {
        throw new Error("Ошибка обновления кальяна");
      }
    } catch (error) {
      console.error("Ошибка обновления кальяна:", error);
      toast({
        title: "Ошибка",
        description: `Ошибка обновления кальяна: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Функция для загрузки изображения кальяна
  const uploadImage = async () => {
    if (!selectedFile) {
      toast({
        title: "Ошибка",
        description: "Пожалуйста, выберите файл для загрузки.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch(
        `http://212.193.27.115:8888/image_upload/update_hookah?hookah_name=${hookah?.name}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${pageStore.token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        toast({
          title: "Успех",
          description: "Изображение успешно загружено",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        const updatedHookah = await response.json();
        setHookah((prevData) => ({ ...prevData, url: updatedHookah.url }));
        updateHookahs();
      } else {
        throw new Error("Ошибка загрузки изображения");
      }
    } catch (error) {
      console.error("Ошибка загрузки изображения:", error);
      toast({
        title: "Ошибка",
        description: `Ошибка загрузки изображения: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Редактирование кальяна</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Название</FormLabel>
            <Input
              value={hookah.name || ""}
              onChange={(e) => setHookah({ ...hookah, name: e.target.value })}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Описание</FormLabel>
            <Textarea
              value={hookah.description || ""}
              onChange={(e) =>
                setHookah({ ...hookah, description: e.target.value })
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Цена</FormLabel>
            <Input
              type="number"
              value={hookah.price || ""}
              onChange={(e) => setHookah({ ...hookah, price: e.target.value })}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Время приготовления (минуты)</FormLabel>
            <Input
              type="number"
              value={hookah.preparation_time || ""}
              onChange={(e) =>
                setHookah({
                  ...hookah,
                  preparation_time: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Загрузить новое изображение</FormLabel>
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
            <Button mt={2} onClick={uploadImage} colorScheme="blue">
              Загрузить изображение
            </Button>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={updateHookah}>
            Сохранить
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Отмена
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default HookahModal;
