import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Heading,
  IconButton,
  useDisclosure,
  VStack,
  Text,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons"; // Иконка редактирования
import { useStores } from "../../store/store_context";
import { useToast } from "@chakra-ui/react";
import OrderModal from "./orders_modal"; // Импортируем компонент модального окна

const OrdersTable = () => {
  const [sortField, setSortField] = useState(null); // Поле для сортировки
  const [sortOrder, setSortOrder] = useState("asc"); // Направление сортировки
  const [searchTerm, setSearchTerm] = useState(""); // Текст для поиска
  const [orders, setOrders] = useState([]); // Список заказов
  const { pageStore } = useStores();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOrderId, setSelectedOrderId] = useState(null); // ID выбранного заказа

  // Функция для получения данных о заказах
  const fetchOrders = async () => {
    try {
      const response = await fetch("http://212.193.27.115:8888/orders/full", {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${pageStore.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setOrders(data);
      } else {
        toast({
          title: "Ошибка",
          description: "Ошибка загрузки данных заказов",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Ошибка:", error);
      toast({
        title: "Ошибка",
        description: `Ошибка: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [pageStore.token]);

  // Функция для сортировки массива заказов
  const sortOrders = (orders, field, order) => {
    return [...orders].sort((a, b) => {
      const fieldA = a[field]?.toString().toLowerCase() || "";
      const fieldB = b[field]?.toString().toLowerCase() || "";

      if (fieldA < fieldB) {
        return order === "asc" ? -1 : 1;
      }
      if (fieldA > fieldB) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Фильтрация заказов по имени пользователя или номеру стола
  const filteredOrders = orders.filter((order) => {
    const fullName =
      `${order.user.first_name} ${order.user.last_name}`.toLowerCase();
    const tableNumber = order.table.number.toString();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      tableNumber.includes(searchTerm)
    );
  });

  // Сортировка заказов в зависимости от выбранного поля
  const sortedOrders = sortField
    ? sortOrders(filteredOrders, sortField, sortOrder)
    : filteredOrders;

  // Переключение сортировки
  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Функция для открытия модального окна редактирования
  const handleEditOrder = (orderId) => {
    setSelectedOrderId(orderId);
    onOpen();
  };
  const handleConfirmDelete = (name, order_id) => {
    // Показываем кастомный тост с кнопками "Подтвердить" и "Отменить"
    toast({
      position: "bottom",
      duration: null, // Тост будет оставаться на экране до действия
      isClosable: true,
      render: ({ onClose }) => (
        <VStack
          bg="gray.700"
          color="white"
          p={4}
          rounded="md"
          spacing={3}
          align="stretch"
        >
          <Text>Вы уверены, что хотите удалить заказ?</Text>
          <VStack spacing={2} align="stretch">
            <Button
              colorScheme="green"
              onClick={async () => {
                const response = await fetch(
                  `http://212.193.27.115:8888/orders/${order_id}`,
                  {
                    method: "DELETE",
                    headers: {
                      accept: "application/json",
                      Authorization: `Bearer ${pageStore.token}`,
                    },
                  }
                );
                if (response.ok) {
                  onClose(); // Закрытие тоста
                  fetchOrders();
                  toast({
                    title: "Заказ удален",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: " Ошибка удаления",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            >
              Подтвердить
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Отменить
            </Button>
          </VStack>
        </VStack>
      ),
    });
  };

  return (
    <Box
      p={4}
      backgroundColor={"white"}
      width={"100%"}
      borderRadius={"12px"}
      overflowX={"scroll"}
      fontFamily='"Montserrat Alternates", sans-serif'
    >
      <Heading as="h2" size="lg" mb={4}>
        Таблица заказов
      </Heading>

      {/* Поиск по имени пользователя или номеру стола */}
      <Input
        placeholder="Поиск по имени пользователя или номеру стола"
        mb={4}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Button
                variant="link"
                onClick={() => toggleSort("user.first_name")}
              >
                Имя пользователя{" "}
                {sortField === "user.first_name" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button variant="link" onClick={() => toggleSort("table.number")}>
                Номер стола{" "}
                {sortField === "table.number" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button variant="link" onClick={() => toggleSort("hall.name")}>
                Зал{" "}
                {sortField === "hall.name" && (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button
                variant="link"
                onClick={() => toggleSort("deposit_amount")}
              >
                Депозит{" "}
                {sortField === "deposit_amount" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button variant="link" onClick={() => toggleSort("date_start")}>
                Начало заказа{" "}
                {sortField === "date_start" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button variant="link" onClick={() => toggleSort("date_finish")}>
                Окончание заказа{" "}
                {sortField === "date_finish" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>Действия</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedOrders.map((order) => (
            <Tr key={order.id}>
              <Td>{`${order.user.first_name} ${order.user.last_name}`}</Td>
              <Td>{order.table.number}</Td>
              <Td>{order.hall.name}</Td>
              <Td>{order.deposit_amount}</Td>
              <Td>{new Date(order.date_start).toLocaleString()}</Td>
              <Td>{new Date(order.date_finish).toLocaleString()}</Td>
              <Td>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => handleEditOrder(order.id)}
                  aria-label="Редактировать заказ"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleConfirmDelete(``, order.id)}
                  aria-label="Delete user"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Модальное окно для редактирования заказа */}
      <OrderModal
        isOpen={isOpen}
        onClose={onClose}
        orderId={selectedOrderId}
        updateOrders={fetchOrders}
      />
    </Box>
  );
};

export default OrdersTable;
