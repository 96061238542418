import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useStores } from "../../store/store_context";

const CreateDishModal = ({updateDishes}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dishData, setDishData] = useState({
    name: "",
    description: "",
    price: "",
    weight_or_volume: "",
    preparation_time: "",
    image_id: 3, // По умолчанию
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDishData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { pageStore } = useStores();

  const handleSubmit = async () => {
    try {
      const response = await fetch("http://212.193.27.115:8888/dishes/", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${pageStore.token}`, // Используйте ваш токен
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...dishData,
          id: 0, // По умолчанию
        }),
      });

      if (!response.ok) {
        throw new Error("Error creating dish");
      }


      const result = await response.json();
      updateDishes(); // Загрузить список блюд после добавления
      toast({
        title: "Dish created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose(); // Закрываем модалку после успешного создания
    } catch (error) {
      toast({
        title: "Error creating dish.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <HStack align={"center"}>
      <Button onClick={onOpen} colorScheme="blue">
        Создать блюдо
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          fontFamily={'"Montserrat Alternates", sans-serif !important'}
        >
          <ModalHeader>Создать блюдо</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Название блюда</FormLabel>
              <Input
                placeholder="Введите название блюда"
                name="name"
                value={dishData.name}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Описание</FormLabel>
              <Input
                placeholder="Введите описание блюда"
                name="description"
                value={dishData.description}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Цена</FormLabel>
              <Input
                placeholder="Введите цену блюда"
                name="price"
                type="number"
                value={dishData.price}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Вес или объем</FormLabel>
              <Input
                placeholder="Введите вес или объем блюда"
                name="weight_or_volume"
                value={dishData.weight_or_volume}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Время приготовления (мин.)</FormLabel>
              <Input
                placeholder="Введите время приготовления"
                name="preparation_time"
                type="number"
                value={dishData.preparation_time}
                onChange={handleChange}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Создать блюдо
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default CreateDishModal;
