import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useStores } from "../../store/store_context";

const CompanyModal = ({ isOpen, onClose, companyId }) => {
  const [company, setCompany] = useState({});
  const { pageStore } = useStores();
  const toast = useToast();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await fetch(
          `http://212.193.27.115:8888/companys/id/${companyId}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${pageStore.token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCompany(data);
        } else {
          toast({
            title: "Ошибка",
            description: "Ошибка загрузки данных компании",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Ошибка:", error);
        toast({
          title: "Ошибка",
          description: `Ошибка: ${error.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    if (companyId) {
      fetchCompany();
    }
  }, [companyId, pageStore.token]);

  const updateCompany = async () => {
    try {
      const response = await fetch(
        `http://212.193.27.115:8888/companys/update/${companyId}`,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${pageStore.token}`,
          },
          body: JSON.stringify(company),
        }
      );

      if (response.ok) {
        toast({
          title: "Успех",
          description: "Компания успешно обновлена",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error("Ошибка обновления компании");
      }
    } catch (error) {
      console.error("Ошибка обновления компании:", error);
      toast({
        title: "Ошибка",
        description: `Ошибка обновления компании: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Редактирование компании</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Адрес</FormLabel>
            <Input
              value={company.address || ""}
              onChange={(e) =>
                setCompany({ ...company, address: e.target.value })
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Телефон</FormLabel>
            <Input
              value={company.phone || ""}
              onChange={(e) =>
                setCompany({ ...company, phone: e.target.value })
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Рабочие часы (формат JSON)</FormLabel>
            <Input
              value={company.working_hours || ""}
              onChange={(e) =>
                setCompany({ ...company, working_hours: e.target.value })
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Социальные сети</FormLabel>
            <Input
              value={company.social_media || ""}
              onChange={(e) =>
                setCompany({ ...company, social_media: e.target.value })
              }
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={updateCompany}>
            Сохранить
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Отмена
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CompanyModal;
