import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useStores } from "../../store/store_context";

const CreateUserModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    birth_date: "",
    gender: "male",
    phone: "",
    password: "",
    role: "user",
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const { pageStore } = useStores();

  const handleSubmit = async () => {
    try {
      const response = await fetch("http://212.193.27.115:8888/users/", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${pageStore.token}`, // Токен вставьте сюда
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...userData,
          id: 0,
          profile_icon_id: 2, // Оставляем по умолчанию
        }),
      });

      if (!response.ok) {
        throw new Error("Error creating user");
      }

      const result = await response.json();
      pageStore.getFullUsers();
      toast({
        title: "User created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose(); // Закрываем модалку после успешного создания
    } catch (error) {
      toast({
        title: "Error creating user.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <HStack align={"center"}>
      <Button onClick={onOpen} colorScheme="blue">
        Создать пользователя
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          fontFamily={'"Montserrat Alternates", sans-serif !important'}
        >
          <ModalHeader>Создать пользователя</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Имя</FormLabel>
              <Input
                placeholder="Enter first name"
                name="first_name"
                value={userData.first_name}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Фамилия</FormLabel>
              <Input
                placeholder="Enter last name"
                name="last_name"
                value={userData.last_name}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Дата рождения</FormLabel>
              <Input
                type="date"
                name="birth_date"
                value={userData.birth_date}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Пол</FormLabel>
              <Select
                name="gender"
                value={userData.gender}
                onChange={handleChange}
              >
                <option value="male">Мужской</option>
                <option value="female">Женский</option>
              </Select>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Телефон</FormLabel>
              <Input
                placeholder="Enter phone number"
                name="phone"
                value={userData.phone}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Пароль</FormLabel>
              <Input
                placeholder="Enter password"
                type="password"
                name="password"
                value={userData.password}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Роль</FormLabel>
              <Select name="role" value={userData.role} onChange={handleChange}>
                <option value="user">Пользователь</option>
                <option value="admin">Администратор</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Создать пользователя
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Отмена
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default CreateUserModal;
