import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RootStoreContext } from "./store/store_context";
import RootStore from "./store/root_store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RootStoreContext.Provider value={new RootStore()}>
    <App />
  </RootStoreContext.Provider>
);
