import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Heading,
  Image,
  IconButton,
  useDisclosure,
  HStack,
  VStack,
  Text
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useStores } from "../../store/store_context";
import HookahModal from "./hookah_modal"; // Модальное окно для редактирования кальяна
import { useToast } from "@chakra-ui/react";
import CreateHookahModal from "./hookah_create_modal";

const HookahsTable = () => {
  const [sortField, setSortField] = useState(null); // Поле для сортировки
  const [sortOrder, setSortOrder] = useState("asc"); // Направление сортировки
  const [searchTerm, setSearchTerm] = useState(""); // Текст для поиска
  const [hookahs, setHookahs] = useState([]); // Список кальянов
  const [selectedHookahId, setSelectedHookahId] = useState(null); // ID выбранного кальяна для редактирования
  const { isOpen, onOpen, onClose } = useDisclosure(); // Управление состоянием модалки
  const { pageStore } = useStores();
  const toast = useToast();

  // Функция для получения данных о кальянах
  const fetchHookahs = async () => {
    try {
      const response = await fetch("http://212.193.27.115:8888/hookahs/full", {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${pageStore.token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setHookahs(data);
      } else {
        toast({
          title: "Ошибка",
          description: "Ошибка загрузки данных кальянов",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Ошибка:", error);
      toast({
        title: "Ошибка",
        description: `Ошибка: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchHookahs();
  }, [pageStore.token]);

  // Функция для сортировки массива кальянов
  // Функция для сортировки массива кальянов
  const sortHookahs = (hookahs, field, order) => {
    return [...hookahs].sort((a, b) => {
      let fieldA = a[field];
      let fieldB = b[field];

      // Если поле - цена или время приготовления, преобразуем значения в числа
      if (field === "price" || field === "preparation_time") {
        fieldA = parseFloat(fieldA) || 0;
        fieldB = parseFloat(fieldB) || 0;
      } else {
        // Для строковых полей используем преобразование в нижний регистр
        fieldA = fieldA?.toString().toLowerCase() || "";
        fieldB = fieldB?.toString().toLowerCase() || "";
      }

      if (fieldA < fieldB) {
        return order === "asc" ? -1 : 1;
      }
      if (fieldA > fieldB) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Фильтрация кальянов по имени или описанию
  const filteredHookahs = hookahs.filter((hookah) => {
    const hookahName = hookah.name.toLowerCase();
    const hookahDescription = hookah.description.toLowerCase();
    return (
      hookahName.includes(searchTerm.toLowerCase()) ||
      hookahDescription.includes(searchTerm.toLowerCase())
    );
  });

  // Сортировка кальянов в зависимости от выбранного поля
  const sortedHookahs = sortField
    ? sortHookahs(filteredHookahs, sortField, sortOrder)
    : filteredHookahs;

  // Переключение сортировки
  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Открытие модалки для редактирования кальяна
  const handleEditHookah = (hookahId) => {
    setSelectedHookahId(hookahId);
    onOpen();
  };
  const handleConfirmDelete = (dish_id) => {
    // Показываем кастомный тост с кнопками "Подтвердить" и "Отменить"
    toast({
      position: "bottom",
      duration: null, // Тост будет оставаться на экране до действия
      isClosable: true,
      render: ({ onClose }) => (
        <VStack
          bg="gray.700"
          color="white"
          p={4}
          rounded="md"
          spacing={3}
          align="stretch"
        >
          <Text>Вы уверены, что хотите удалить кальян?</Text>
          <VStack spacing={2} align="stretch">
            <Button
              colorScheme="green"
              onClick={async () => {
                const response = await fetch(
                  `http://212.193.27.115:8888/hookahs/${dish_id}`,
                  {
                    method: "DELETE",
                    headers: {
                      accept: "application/json",
                      Authorization: `Bearer ${pageStore.token}`,
                    },
                  }
                );
                if (response.ok) {
                  onClose(); // Закрытие тоста
                  fetchHookahs();
                  toast({
                    title: "Кальян удален",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: " Ошибка удаления",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            >
              Подтвердить
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Отменить
            </Button>
          </VStack>
        </VStack>
      ),
    });
  };

  return (
    <Box
      p={4}
      backgroundColor={"white"}
      width={"100%"}
      borderRadius={"12px"}
      overflowX={"scroll"}
      fontFamily='"Montserrat Alternates", sans-serif'
    >
      <Heading as="h2" size="lg" mb={4}>
        Таблица кальянов
      </Heading>

      {/* Поиск по имени или описанию кальяна */}
      <HStack width={"100%"} justify={"space-between"}>
        <Input
          placeholder="Поиск по имени или описанию кальяна"
          mb={4}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CreateHookahModal updateHookahs={fetchHookahs} />
      </HStack>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Button variant="link" onClick={() => toggleSort("name")}>
                Название{" "}
                {sortField === "name" && (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>Описание</Th>
            <Th>
              <Button variant="link" onClick={() => toggleSort("price")}>
                Цена{" "}
                {sortField === "price" && (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button
                variant="link"
                onClick={() => toggleSort("preparation_time")}
              >
                Время приготовления{" "}
                {sortField === "preparation_time" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>Изображение</Th>
            <Th>Действия</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedHookahs.map((hookah) => (
            <Tr key={hookah.id}>
              <Td>{hookah.name}</Td>
              <Td>{hookah.description}</Td>
              <Td>{hookah.price}</Td>
              <Td>{hookah.preparation_time} мин.</Td>
              <Td>
                <Image
                  src={hookah.url}
                  alt={hookah.name}
                  boxSize="100px"
                  objectFit="cover"
                />
              </Td>
              <Td>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => handleEditHookah(hookah.id)}
                  aria-label="Edit Hookah"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleConfirmDelete(hookah.id)}
                  aria-label="Delete hookah"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Модальное окно для редактирования кальяна */}
      {isOpen && (
        <HookahModal
          isOpen={isOpen}
          onClose={onClose}
          hookahId={selectedHookahId}
          updateHookahs={fetchHookahs}
        />
      )}
    </Box>
  );
};

export default HookahsTable;
