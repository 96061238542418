import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  IconButton,
  VStack,
  Text
} from "@chakra-ui/react";
import { useStores } from "../../store/store_context";
import CreateDishModal from "./dish_create_modal";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

// Модалка для редактирования блюда
const DishModal = ({ isOpen, onClose, dish, updateDish }) => {
  const [updatedDish, setUpdatedDish] = useState(dish);

  useEffect(() => {
    setUpdatedDish(dish); // обновляем данные блюда при открытии модалки
  }, [dish]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDish({ ...updatedDish, [name]: value });
  };
  const { pageStore } = useStores();

  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    try {
      const response = await fetch(
        `http://212.193.27.115:8888/image_upload/update_dish?dish_name=${updatedDish.name}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${pageStore.token}`,
          },
          body: formData,
        }
      );
      if (response.ok) {
        console.log("Изображение обновлено");
      }
    } catch (error) {
      console.error("Ошибка загрузки изображения:", error);
    }
  };

  const handleUpdateDish = () => {
    updateDish(updatedDish);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Редактировать блюдо</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Название блюда</FormLabel>
            <Input
              name="name"
              value={updatedDish.name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Описание</FormLabel>
            <Input
              name="description"
              value={updatedDish.description}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Цена</FormLabel>
            <Input
              name="price"
              value={updatedDish.price}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Вес/Объем</FormLabel>
            <Input
              name="weight_or_volume"
              value={updatedDish.weight_or_volume}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Время приготовления</FormLabel>
            <Input
              name="preparation_time"
              value={updatedDish.preparation_time}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Изображение</FormLabel>
            <Input type="file" onChange={handleImageUpload} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleUpdateDish}>
            Сохранить
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Отмена
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DishesTable = () => {
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [dishes, setDishes] = useState([]);
  const [selectedDish, setSelectedDish] = useState(null); // Выбранное блюдо для редактирования
  const [isModalOpen, setIsModalOpen] = useState(false); // Управление модалкой
  const { pageStore } = useStores();
  const toast = useToast();

  const fetchDishes = async () => {
    try {
      const response = await fetch("http://212.193.27.115:8888/dishes/full", {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${pageStore.token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setDishes(data);
      } else {
        toast({
          title: "Ошибка",
          description: "Ошибка загрузки данных блюд",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Ошибка:", error);
      toast({
        title: "Ошибка",
        description: `Ошибка: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchDishes();
  }, [pageStore.token]);

  const sortDishes = (dishes, field, order) => {
    return [...dishes].sort((a, b) => {
      const fieldA = a[field]?.toString().toLowerCase() || "";
      const fieldB = b[field]?.toString().toLowerCase() || "";
      if (fieldA < fieldB) {
        return order === "asc" ? -1 : 1;
      }
      if (fieldA > fieldB) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const filteredDishes = dishes.filter((dish) => {
    const dishName = dish.name.toLowerCase();
    const dishDescription = dish.description.toLowerCase();
    return (
      dishName.includes(searchTerm.toLowerCase()) ||
      dishDescription.includes(searchTerm.toLowerCase())
    );
  });

  const sortedDishes = sortField
    ? sortDishes(filteredDishes, sortField, sortOrder)
    : filteredDishes;

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const openModal = (dish) => {
    setSelectedDish(dish);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateDish = async (updatedDish) => {
    try {
      const response = await fetch(
        `http://212.193.27.115:8888/dishes/${updatedDish.id}`,
        {
          method: "PUT",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${pageStore.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedDish),
        }
      );
      if (response.ok) {
        fetchDishes(); // Обновить список блюд
        toast({
          title: "Успешно",
          description: "Блюдо обновлено",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        closeModal();
      }
    } catch (error) {
      console.error("Ошибка обновления блюда:", error);
    }
  };
  const handleConfirmDelete = ( dish_id) => {
    // Показываем кастомный тост с кнопками "Подтвердить" и "Отменить"
    toast({
      position: "bottom",
      duration: null, // Тост будет оставаться на экране до действия
      isClosable: true,
      render: ({ onClose }) => (
        <VStack
          bg="gray.700"
          color="white"
          p={4}
          rounded="md"
          spacing={3}
          align="stretch"
        >
          <Text>Вы уверены, что хотите удалить блюдо?</Text>
          <VStack spacing={2} align="stretch">
            <Button
              colorScheme="green"
              onClick={async () => {
                const response = await fetch(
                  `http://212.193.27.115:8888/dishes/${dish_id}`,
                  {
                    method: "DELETE",
                    headers: {
                      accept: "application/json",
                      Authorization: `Bearer ${pageStore.token}`,
                    },
                  }
                );
                if (response.ok) {
                  onClose(); // Закрытие тоста
                  fetchDishes();
                  toast({
                    title: "Блюдо удалено",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: " Ошибка удаления",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            >
              Подтвердить
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Отменить
            </Button>
          </VStack>
        </VStack>
      ),
    });
  };

  return (
    <Box
      p={4}
      backgroundColor={"white"}
      width={"100%"}
      borderRadius={"12px"}
      overflowX={"scroll"}
      fontFamily='"Montserrat Alternates", sans-serif'
    >
      <Heading as="h2" size="lg" mb={4}>
        Таблица блюд
      </Heading>
      <HStack width={"100%"} justify={"space-between"}>
        <Input
          placeholder="Поиск по имени или описанию блюда"
          mb={4}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CreateDishModal updateDishes={fetchDishes} />
      </HStack>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              <Button variant="link" onClick={() => toggleSort("name")}>
                Название блюда{" "}
                {sortField === "name" && (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button variant="link" onClick={() => toggleSort("price")}>
                Цена{" "}
                {sortField === "price" && (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button
                variant="link"
                onClick={() => toggleSort("weight_or_volume")}
              >
                Вес / Объем{" "}
                {sortField === "weight_or_volume" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>
              <Button
                variant="link"
                onClick={() => toggleSort("preparation_time")}
              >
                Время приготовления{" "}
                {sortField === "preparation_time" &&
                  (sortOrder === "asc" ? "↑" : "↓")}
              </Button>
            </Th>
            <Th>Фото</Th>
            <Th>Действия</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedDishes.map((dish) => (
            <Tr key={dish.id}>
              <Td>{dish.name}</Td>
              <Td>{dish.price}</Td>
              <Td>{dish.weight_or_volume}</Td>
              <Td>{dish.preparation_time}</Td>
              <Td>
                <Image
                  src={dish.url}
                  alt={dish.name}
                  boxSize="100px"
                  objectFit={"cover"}
                />
              </Td>
              <Td>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => openModal(dish)}
                  aria-label="Edit dish"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleConfirmDelete(dish.id)}
                  aria-label="Delete dish"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Модальное окно для редактирования */}
      {selectedDish && (
        <DishModal
          isOpen={isModalOpen}
          onClose={closeModal}
          dish={selectedDish}
          updateDish={updateDish}
        />
      )}
    </Box>
  );
};

export default DishesTable;
