import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useStores } from "../store/store_context";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/react";
import stars from "./../images/stars_background.png";

// Определяем схему валидации с помощью Yup
const validationSchema = Yup.object({
  phone: Yup.string()
    .matches(
      /^\+?\d{1,3}?\s?\(?\d{1,4}?\)?[\d\s-]{5,}$/,
      "Неверный формат телефона"
    )
    .required("Телефон обязателен"),
  password: Yup.string()
    .min(6, "Пароль должен быть минимум 6 символов")
    .required("Пароль обязателен"),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const handleSubmit = async (values) => {
    console.log("Данные формы:", values);
    const [result, response] = await pageStore.signIn(values);
    if (response.ok) {
      pageStore.getCurrentAuthUser();
      pageStore.token && navigate("/main");
    } else {
      toast({
        title: "Ошибка авторизации!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }

    // Здесь можно добавить логику для отправки данных на сервер
  };
  const { pageStore } = useStores();

  return (
    <VStack
      width={"100%"}
      minH={"100vh"}
      backgroundColor={"rgba(14, 74, 74, 1)"}
      backgroundImage={stars}
      color={"white"}
    >
      <Box maxW="md" mx="auto" mt="10">
        <Heading
          as="h2"
          size="lg"
          mb="6"
          textAlign="center"
          fontFamily={"Montserrat Alternates"}
        >
          Вход
        </Heading>
        <Formik
          initialValues={{ phone: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormControl isInvalid={errors.phone && touched.phone} mb="4">
                <FormLabel htmlFor="phone">Телефон</FormLabel>
                <Field
                  as={Input}
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="+7 (999) 999-99-99"
                />
                <FormErrorMessage>{errors.phone}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.password && touched.password}
                mb="4"
              >
                <FormLabel htmlFor="password">Пароль</FormLabel>
                <Field
                  as={Input}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Введите пароль"
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>

              <Button colorScheme="teal" type="submit" width="full" mt="4">
                Войти
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default LoginPage;
