export const getUsers = async (token) => {
  const url = "http://212.193.27.115:8888/users/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createUser = async (token, userData) => {
  const url = "http://212.193.27.115:8888/users/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если это нужно
    },
    body: JSON.stringify(userData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
export async function getUsersFull(token) {
  const url = "http://212.193.27.115:8888/users/full";

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
}

export const getUserById = async (token, userId) => {
  const url = `http://212.193.27.115:8888/users/id/${userId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getUserByPhone = async (token, phoneNumber) => {
  const url = `http://212.193.27.115:8888/users/phone/${phoneNumber}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateUser = async (token, userId, userData) => {
  const url = `http://212.193.27.115:8888/users/${userId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если это требуется
    },
    body: JSON.stringify(userData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getOrders = async (token) => {
  const url = "http://212.193.27.115:8888/orders/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createOrder = async (token, orderData) => {
  const url = "http://212.193.27.115:8888/orders/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если это требуется
    },
    body: JSON.stringify(orderData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getOrderById = async (token, orderId) => {
  const url = `http://212.193.27.115:8888/orders/id/${orderId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если это требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getOrdersByUserId = async (token, userId) => {
  const url = `http://212.193.27.115:8888/orders/user/${userId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если это требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const addDishOrHookahToOrder = async (token, orderId, items) => {
  const url = `http://212.193.27.115:8888/orders/add_dish_or_hookah_to_order/${orderId}`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если это требуется
    },
    body: JSON.stringify(items), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHistoryOfDishAndHookah = async (token, orderId) => {
  const url = `http://212.193.27.115:8888/orders/get_hystory_of_dish_and_hookah/${orderId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateOrder = async (token, orderId, orderData) => {
  const url = `http://212.193.27.115:8888/orders/${orderId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(orderData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const reserveTable = async (token, reservationData) => {
  const url = "http://212.193.27.115:8888/orders/reserve_table/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(reservationData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getCategories = async (token) => {
  const url = "http://212.193.27.115:8888/categories/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createCategory = async (token, categoryData) => {
  const url = "http://212.193.27.115:8888/categories/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(categoryData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getCategoryById = async (token, categoryId) => {
  const url = `http://212.193.27.115:8888/categories/id/${categoryId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateCategory = async (token, categoryId, categoryData) => {
  const url = `http://212.193.27.115:8888/categories/${categoryId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(categoryData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getBaskets = async (token) => {
  const url = "http://212.193.27.115:8888/baskets/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createBasket = async (token, basketData) => {
  const url = "http://212.193.27.115:8888/baskets/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(basketData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getBasketById = async (token, basketId) => {
  const url = `http://212.193.27.115:8888/baskets/id/${basketId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateBasket = async (token, basketId, basketData) => {
  const url = `http://212.193.27.115:8888/baskets/${basketId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(basketData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getDishes = async (token) => {
  const url = "http://212.193.27.115:8888/dishes/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createDish = async (token, dishData) => {
  const url = "http://212.193.27.115:8888/dishes/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(dishData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getDishById = async (token, dishId) => {
  const url = `http://212.193.27.115:8888/dishes/id/${dishId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateDish = async (token, dishId, dishData) => {
  const url = `http://212.193.27.115:8888/dishes/${dishId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(dishData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getDishCategories = async (token) => {
  const url = "http://212.193.27.115:8888/dish_categories/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createDishCategory = async (token, dishCategoryData) => {
  const url = "http://212.193.27.115:8888/dish_categories/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(dishCategoryData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getDishCategoryById = async (token, dishCategoryId) => {
  const url = `http://212.193.27.115:8888/dish_categories/id/${dishCategoryId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateDishCategory = async (
  token,
  dishCategoryId,
  dishCategoryData
) => {
  const url = `http://212.193.27.115:8888/dish_categories/${dishCategoryId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(dishCategoryData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getFavorites = async (token) => {
  const url = "http://212.193.27.115:8888/favorites/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const addFavorite = async (token, favoriteData) => {
  const url = "http://212.193.27.115:8888/favorites/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(favoriteData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getFavoriteById = async (token, favoriteId) => {
  const url = `http://212.193.27.115:8888/favorites/id/${favoriteId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateFavorite = async (token, favoriteId, favoriteData) => {
  const url = `http://212.193.27.115:8888/favorites/${favoriteId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(favoriteData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const addFavoriteLikes = async (token, favoritesData) => {
  const url = "http://212.193.27.115:8888/favorites/likes_products";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(favoritesData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHalls = async (token) => {
  const url = "http://212.193.27.115:8888/halls/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createHall = async (token, hallData) => {
  const url = "http://212.193.27.115:8888/halls/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hallData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHallById = async (token, hallId) => {
  const url = `http://212.193.27.115:8888/halls/id/${hallId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateHall = async (token, hallId, hallData) => {
  const url = `http://212.193.27.115:8888/halls/${hallId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hallData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHallsTables = async (token) => {
  const url = "http://212.193.27.115:8888/halls_tables/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createHallTable = async (token, hallTableData) => {
  const url = "http://212.193.27.115:8888/halls_tables/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hallTableData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHallTableById = async (token, hallTableId) => {
  const url = `http://212.193.27.115:8888/halls_tables/id/${hallTableId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateHallTable = async (token, hallTableId, hallTableData) => {
  const url = `http://212.193.27.115:8888/halls_tables/${hallTableId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hallTableData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHookahs = async (token) => {
  const url = "http://212.193.27.115:8888/hookahs/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createHookah = async (token, hookahData) => {
  const url = "http://212.193.27.115:8888/hookahs/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hookahData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHookahById = async (token, hookahId) => {
  const url = `http://212.193.27.115:8888/hookahs/id/${hookahId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateHookah = async (token, hookahId, hookahData) => {
  const url = `http://212.193.27.115:8888/hookahs/${hookahId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hookahData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHookahCategories = async (token) => {
  const url = "http://212.193.27.115:8888/hookah_categories/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createHookahCategory = async (token, hookahCategoryData) => {
  const url = "http://212.193.27.115:8888/hookah_categories/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hookahCategoryData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getHookahCategoryById = async (token, categoryId) => {
  const url = `http://212.193.27.115:8888/hookah_categories/id/${categoryId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateHookahCategory = async (
  token,
  categoryId,
  hookahCategoryData
) => {
  const url = `http://212.193.27.115:8888/hookah_categories/${categoryId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(hookahCategoryData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getOrdersProducts = async (token) => {
  const url = "http://212.193.27.115:8888/orders_products/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const addOrderProduct = async (token, orderProductData) => {
  const url = "http://212.193.27.115:8888/orders_products/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(orderProductData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getOrderProductById = async (token, productId) => {
  const url = `http://212.193.27.115:8888/orders_products/id/${productId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateOrderProduct = async (
  token,
  productId,
  orderProductData
) => {
  const url = `http://212.193.27.115:8888/orders_products/${productId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(orderProductData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getTables = async (token) => {
  const url = "http://212.193.27.115:8888/tables/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createTable = async (token, tableData) => {
  const url = "http://212.193.27.115:8888/tables/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(tableData), // преобразование данных в JSON-формат
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getTableById = async (token, tableId) => {
  const url = `http://212.193.27.115:8888/tables/id/${tableId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getTableByNumber = async (token, tableNumber) => {
  const url = `http://212.193.27.115:8888/tables/table_number/${tableNumber}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateTable = async (token, tableId, number, seats) => {
  const url = `http://212.193.27.115:8888/tables/${tableId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify({
      id: tableId,
      number,
      seats,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getVipRooms = async (token) => {
  const url = "http://212.193.27.115:8888/vip_rooms/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createVipRoom = async (token, hallId, tableId) => {
  const url = "http://212.193.27.115:8888/vip_rooms/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify({
      id: 0,
      hall_id: hallId,
      table_id: tableId,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getVipRoomById = async (token, id) => {
  const url = `http://212.193.27.115:8888/vip_rooms/id/${id}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateVipRoom = async (token, id, hallId, tableId) => {
  const url = `http://212.193.27.115:8888/vip_rooms/${id}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify({
      id: id,
      hall_id: hallId,
      table_id: tableId,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getCompanies = async (token) => {
  const url = "http://212.193.27.115:8888/companys/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createCompany = async (companyData, token) => {
  const url = "http://212.193.27.115:8888/companys/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(companyData),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getCompanyById = async (companyId, token) => {
  const url = `http://212.193.27.115:8888/companys/id/${companyId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateCompany = async (companyId, companyData, token) => {
  const url = `http://212.193.27.115:8888/companys/${companyId}`;

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(companyData),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getImages = async (token) => {
  const url = "http://212.193.27.115:8888/images/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const createImage = async (imageData, token) => {
  const url = "http://212.193.27.115:8888/images/";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify(imageData),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const getImageById = async (id, token) => {
  const url = `http://212.193.27.115:8888/images/id/${id}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateImage = async (id, url, token) => {
  const apiUrl = `http://212.193.27.115:8888/images/${id}`;

  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // передача токена, если требуется
    },
    body: JSON.stringify({
      id: id,
      url: url,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
