import { ChakraProvider } from "@chakra-ui/react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/login_page";
import PrivateRoute from "./pages/private_page";
import MainPage from "./pages/main_page";

const router = createHashRouter([
  { element: <LoginPage />, path: "/" },
  {
    element: (
      <PrivateRoute>
        <MainPage />
      </PrivateRoute>
    ),
    path: "/main",
  },
]);
const App = () => {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};

export default App;
